import React from 'react';
import { Link } from "react-router-dom";
import Logo from '../../../dist/images/logo.svg';

const Footer = () => {
    return (
        <footer id="footer" className='py-4'>
            <div className="container-fluid footer-container">
                <div className="footer-top">
                    <div className="row">
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-widget logo-widget">
                                <Link to="/">
                                    <img src={Logo} alt="Logo-img" />
                                </Link>
                                <div className="footer-social">
                                    <ul className="social-list d-flex">
                                        <li><a href="https://www.facebook.com/profile.php?id=100077259540986" target="_blank" rel="noreferrer" className='fb'><i className="fa fa-facebook" /></a></li>
                                        <li><a href="https://www.instagram.com/deborahjordanstudio/" target="_blank" rel="noreferrer" className='insta'><i className="fab fa-instagram" /></a></li>
                                        {/* <li><a href="https://twitter.com/chuzeday" target="_blank" rel="noreferrer"><i className="fab fa-twitter" /></a></li> */}
                                        {/* <li><Link to="/"><i className="fab fa-youtube"></i></Link></li>
                                        <li><Link to="/"><i className="fab fa-whatsapp"></i></Link></li>
                                        <li><Link to="/"><i className="fab fa-pinterest"></i></Link></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-6">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-6">
                                    <div className="footer-widget">
                                        <h3>About Chuzeday</h3>
                                        <ul className="footer-link">
                                            <li><Link to="/system/about">About Us</Link></li>
                                            <li><Link to="/system/guest-query/careers">Careers at</Link></li>
                                            <li><Link to="/system/guest-query/general">Contact Us</Link></li>
                                            <li><Link to="/system/blog">Blog</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-6">
                                    <div className="footer-widget">
                                        <h3>For Business</h3>
                                        <ul className="footer-link">
                                            <li><Link to="/for-business">For Business</Link></li>
                                            <li><Link to="/system/guest-query/pricing">Pricing</Link></li>
                                            <li><Link to="/system/guest-query/business_support">Support for business</Link></li>
                                            <li><Link to="/system/faq">FAQ</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="footer-widget">
                                        <h3>Legal</h3>
                                        <ul className="footer-link">
                                            <li><Link to="/system/booking-terms">Booking terms</Link></li>
                                            <li><Link to="/system/privacy-policy">Privacy policy</Link></li>
                                            <li><Link to="/system/web-terms">Website terms</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="footer-widget">
                                        <a href="https://business.chuzeday.com/auth/registration" rel="noreferrer" className='btn btn-lg mt-2 border-white border-3 fw-bolder p-4 w-100 business'>
                                            Trial Chuzeday Business
                                        </a>
                                        <Link to="/signup" className='btn btn-lg mt-3 border-white border-3 fw-bolder py-4 px-5 w-100 guest'>
                                            Join Chuzeday
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
export default Footer;